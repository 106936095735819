export const podiumUIdependencies = [
  "core-web",
  "styles-web",
  "themes-web",
  "icons-web"
];
export const podiumUISharedDependenciess = ["shared-web"];

export const podiumUIDataDependencies = [
  "table",
  "chart",
  "formobject",
  "customise",
  "calendar",
  "ganttcomponent",
  "documents",
  "customsave",
  "metadatawrapper",
  "genericcomponent",
  "legacy",
  "filters",
  "querybuilder",
  "tabswrapper",
  "actionbar",
  "underconstruction",
  "orderhistory",
  "drawerpanel",
  "printable",
  "todolist",
  "map",
  "echart",
  "layoutbuilder",
  "componentselector",
];
